// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery")
require("trix")
require("@rails/actiontext")

// var Trix = require("trix");
//
// /* what the newly created button does */
// Trix.config.textAttributes.red = {
//   style: { color: "red" },
//   parser: function(element) {
//       return element.style.color === "red"
//   },
//   inheritable: true
// }
//
// Trix.config.textAttributes.heading = { inheritable: true, tagName: 'h1' };
//
// Trix.config.textAttributes.subHeading = { inheritable: true, tagName: 'h2' };
// Trix.config.textAttributes.buttonHTML = { inheritable: true, tagName: 'HTML' };
//
// /* insert the button visual in the default toolbar */
// addEventListener("trix-initialize", function(event) {
//   var buttonHTML = '<button type="button" data-trix-attribute="red">RED</button>'
//
//   event.target.toolbarElement.
//   querySelector(".trix-button-group").
//   insertAdjacentHTML("beforeend", buttonHTML)
// })
//
// addEventListener("trix-initialize", function(event) {
//   var buttonHTML = '<button type="button" data-trix-attribute="Heading">H1</button>'
//
//   event.target.toolbarElement.
//   querySelector(".trix-button-group").
//   insertAdjacentHTML("beforeend", buttonHTML)
// })
//
// addEventListener("trix-initialize", function(event) {
//   var buttonHTML = '<button type="button" data-trix-attribute="subHeading">H2</button>'
//
//   event.target.toolbarElement.
//   querySelector(".trix-button-group").
//   insertAdjacentHTML("beforeend", buttonHTML)
// })
//
// addEventListener("trix-initialize", function(event) {
//   var buttonHTML = '<button type="button" data-trix-attribute="buttonHTML">HTML</button>'
//
//   event.target.toolbarElement.
//   querySelector(".trix-button-group").
//   insertAdjacentHTML("beforeend", buttonHTML)
// })
